import { computed, onBeforeMount, onBeforeUpdate, ref } from "vue";
import { useRoute } from "vue-router";
import TabInterface from "@/types/TabInterface";

export default (tabs: TabInterface[]) => {
  const activeTab = ref("");

  onBeforeMount(() => {
    setActiveTab();
  });
  onBeforeUpdate(() => {
    setActiveTab();
  });

  const currentRoute = useRoute();
  const currentRouteName = computed(() => currentRoute.name);

  const setActiveTab = () => {
    activeTab.value =
      tabs.find((tab) => tab.value === currentRouteName.value)?.name ||
      tabs[0]?.name ||
      "";
  };

  return {
    activeTab,
    setActiveTab,
  };
};
