import { h } from "vue";
import { RouterLink, useRoute } from "vue-router";

export default () => {
  const route = useRoute();
  const renderLabel = (
    label: string,
    routeName: string,
    replace = false,
    params?:
      | Record<string, string | number | (string | number)[] | null | undefined>
      | undefined
  ) => {
    return () =>
      h(
        RouterLink,
        {
          to: {
            name: routeName,
            params: params || route.params,
            replace,
          },
        },
        { default: () => label }
      );
  };

  return {
    renderLabel,
  };
};
